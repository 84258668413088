import { ApiPaths } from 'services/api/apiPaths';
import { SubscriptionPostParams } from '../../../../store/createSubscription/createSubscription.types';
import { createResourceApi } from '../../createResourceApi';

export const subscriptionsApi = createResourceApi(ApiPaths.SUBSCRIPTIONS, (rest) => ({
  getAll: (params: GetSubscriptionParams) =>
    rest.get<GetSubscriptionsResponse>({ config: { params } }),

  getOne: (id: number) => rest.get<{ subscription: Subscription }>({ path: `${id}` }),

  update: (subscription: Subscription) =>
    rest.put<{ subscription: Subscription }>({
      path: `${subscription.id}`,
      data: { subscription }
    }),

  create: (params: SubscriptionPostParams) =>
    rest.post<{ subscription: Subscription }>({
      data: { subscription: params.subscription },
      config: { headers: params.headers }
    }),

  remove: ({ subscriptionId, params }: DeleteSubscriptionRequestPayload) =>
    rest.delete({ path: `${subscriptionId}`, config: { params } }),

  activate: ({
    subscription,
    activationKey
  }: {
    subscription: Subscription;
    activationKey: string;
  }) =>
    rest.post<{ subscription: Subscription }>({
      path: `${subscription.id}/actions/activate?key=${activationKey}`,
      data: { subscription },
      config: { params: { t: new Date().getTime() } }
    })
}));
