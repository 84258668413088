import { capitalize } from 'lodash';
import {
  ShardPricingObject,
  CreateShardTypesObject,
  GetPriceDataProps
} from './rcpUpdateFlow.types';
import { getShardTypeName } from '../../utils/shardAndDbsHelpers';

import { NewPriceDialogRow } from './databases.types';

export const getPriceData = ({
  isCreateMode,
  selectedSubscription,
  selectedDatabase,
  subscriptionBdbs,
  newDbShardPricingList,
  shardTypes
}: GetPriceDataProps) => {
  const currentShardPricingList = subscriptionBdbs.flatMap(
    (bdb) => bdb.shard_type_pricing_bdb_regions
  );

  const newShardPricingList = isCreateMode
    ? [...currentShardPricingList, ...newDbShardPricingList]
    : subscriptionBdbs.flatMap((bdb) => {
        if (selectedDatabase.id === bdb.id) {
          return newDbShardPricingList;
        }

        return bdb.shard_type_pricing_bdb_regions;
      });

  const currentShardPricingObject = createShardTypesObject(currentShardPricingList, shardTypes);
  const newShardPricingObject = createShardTypesObject(newShardPricingList, shardTypes);

  const shardTypesToShow = Object.keys({ ...newShardPricingObject, ...currentShardPricingObject });

  const newPriceTableRows: NewPriceDialogRow[] = shardTypesToShow.map((shardType) => {
    const valueInCurrent = currentShardPricingObject[shardType];
    const valueInNew = newShardPricingObject[shardType];

    return {
      id: shardType,
      shardType: capitalize(shardType),
      shardPrice: valueInCurrent?.shard_type_region_price || valueInNew?.shard_type_region_price,
      existingQty: valueInCurrent?.shards_count || 0,
      requiredQty: valueInNew?.shards_count || 0,
      totalShardPrice: valueInNew?.shards_count
        ? valueInNew?.shards_count * valueInNew?.shard_type_region_price
        : 0
    };
  });

  const currentPrice = getPriceFromShardsObject(
    currentShardPricingObject,
    selectedSubscription.minimal_pricing_regions
  );

  const newPrice = getPriceFromShardsObject(
    newShardPricingObject,
    selectedSubscription.minimal_pricing_regions
  );

  return { newPriceTableRows, currentPrice, newPrice, isPriceHigher: newPrice > currentPrice };
};

const createShardTypesObject: CreateShardTypesObject = (shardTypePricingRegion, shardTypes) => {
  return shardTypePricingRegion.reduce((acc, currShard) => {
    const shardType = getShardTypeName(currShard.shard_type_id, shardTypes);

    if (!acc[shardType]) {
      acc[shardType] = currShard;

      return acc;
    }
    acc[shardType] = {
      ...acc[shardType],
      shards_count: acc[shardType].shards_count + currShard.shards_count,
      hourly_shard_price:
        acc[shardType].hourly_shard_price +
        currShard.shards_count * currShard.shard_type_region_price
    };

    return acc;
  }, {} as ShardPricingObject);
};

const getPriceFromShardsObject = (
  shardPricingObject: ShardPricingObject,
  minimalPricings: SubsMinimalPricing[]
) => {
  const price = Object.values(shardPricingObject).reduce(
    (acc, shardInfo) => acc + shardInfo.hourly_shard_price,
    0
  );

  return Math.max(price, minimalPricings[0]?.price || 0);
};
