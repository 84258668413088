export enum AccountActionTypes {
  GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE',

  UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE',

  GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE',

  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',

  CREATE_CLOUD_API_REQUEST = 'CREATE_ACCOUNT_API_REQUEST',
  CREATE_CLOUD_API_SUCCESS = 'CREATE_ACCOUNT_API_SUCCESS',

  POST_MARKETPLACE_ACCOUNT_ID_REQUEST = 'POST_MARKETPLACE_ACCOUNT_ID_REQUEST',

  POST_MARKETPLACE_ACCOUNT_ID_FAILURE = 'POST_MARKETPLACE_ACCOUNT_ID_FAILURE',

  SET_PRODUCT_TYPE_REQUEST = 'SET_PRODUCT_TYPE_REQUEST',
  SET_PRODUCT_TYPE_SUCCESS = 'SET_PRODUCT_TYPE_SUCCESS',
  SET_PRODUCT_TYPE_FAILURE = 'SET_PRODUCT_TYPE_FAILURE'
}

export interface GetAccountRequestAction {
  type: AccountActionTypes.GET_ACCOUNT_REQUEST;
}

export interface GetAccountSuccessAction {
  type: AccountActionTypes.GET_ACCOUNT_SUCCESS;
  payload: Account;
}

export interface GetAccountFailureAction {
  type: AccountActionTypes.GET_ACCOUNT_FAILURE;
  payload: {
    error?: string;
    status?: number;
  };
}

export interface UpdateAccountRequestAction {
  type: AccountActionTypes.UPDATE_ACCOUNT_REQUEST;
  payload: {
    formPayload: Account;
    finallyCallback: () => void;
  };
}

export interface UpdateAccountSuccessAction {
  type: AccountActionTypes.UPDATE_ACCOUNT_SUCCESS;
  payload: Account;
}

export interface UpdateAccountFailureAction {
  type: AccountActionTypes.UPDATE_ACCOUNT_FAILURE;
  payload: string;
}

export interface GetAccountsRequestAction {
  type: AccountActionTypes.GET_ACCOUNTS_REQUEST;
}

export interface GetAccountsSuccessAction {
  type: AccountActionTypes.GET_ACCOUNTS_SUCCESS;
  payload: Account[];
}

export interface GetAccountsFailureAction {
  type: AccountActionTypes.GET_ACCOUNTS_FAILURE;
  payload: string;
}

export interface SwitchAccountAction {
  type: AccountActionTypes.SWITCH_ACCOUNT;
  payload: number;
}

export interface CreateCloudApiRequestAction {
  type: AccountActionTypes.CREATE_CLOUD_API_REQUEST;
}

export interface CreateCloudApiSuccessAction {
  type: AccountActionTypes.CREATE_CLOUD_API_SUCCESS;
  payload: string;
}

interface AssignMarketplaceAccountIdRequestActionPayload {
  selectedAccountId: number;
  marketplaceAccountId: string;
  shouldSetAccount: boolean;
  successCb: () => void;
  errorCb: () => void;
}

export interface AssignMarketplaceAccountIdRequestAction {
  type: AccountActionTypes.POST_MARKETPLACE_ACCOUNT_ID_REQUEST;
  payload: AssignMarketplaceAccountIdRequestActionPayload;
}

export interface SetProductTypeRequestAction {
  type: AccountActionTypes.SET_PRODUCT_TYPE_REQUEST;
  payload: ProductType;
}

export interface SetProductTypeSuccessAction {
  type: AccountActionTypes.SET_PRODUCT_TYPE_SUCCESS;
  payload: ProductType;
}

export interface SetProductTypeFailureAction {
  type: AccountActionTypes.SET_PRODUCT_TYPE_FAILURE;
}

export type AccountActions =
  | GetAccountRequestAction
  | GetAccountSuccessAction
  | GetAccountFailureAction
  | UpdateAccountRequestAction
  | UpdateAccountSuccessAction
  | UpdateAccountFailureAction
  | GetAccountsRequestAction
  | GetAccountsSuccessAction
  | GetAccountsFailureAction
  | CreateCloudApiSuccessAction
  | AssignMarketplaceAccountIdRequestAction
  | SetProductTypeRequestAction;

export interface AccountState extends BaseState {
  data: Account;
  httpStatus?: number;
}

export interface AccountsState extends BaseState {
  data: Account[];
}

interface IOwner {
  id: number;
  account: number;
  name: string;
  email: string;
  password?: string;
  old_password?: string;
  title: string;
  permission: string;
  billing: boolean;
  email_alerts: boolean;
  operational_emails: boolean;
  signup_date: Date | string;
  mfa_enabled: boolean;
  has_any_account_with_mfa_enforcement: boolean;
  rcrv_popup_was_displayed: boolean;
  has_api_key: boolean;
  errors: unknown[];
  user_type?: string;
}

export type MarketPlaceProviders = 'aws' | 'gcp' | 'azure';

type MarketplaceStatus =
  | 'pending'
  | 'account_pending'
  | 'create_contract_pending'
  | 'contract_pending'
  | 'contract_change_pending'
  | 'delete_pending'
  | 'deleted'
  | 'active';

export interface Marketplace {
  provider: MarketPlaceProviders;
  product: string;
  status: MarketplaceStatus;
  support_non_hosted_cloud_account?: boolean;
  order_id?: string;
}

type Paas =
  | 'Heroku'
  | 'Vercel'
  | 'AppFog'
  | 'AppHarbor'
  | 'AzureStore'
  | 'CLOUDFOUNDRY'
  | 'DotCloud'
  | 'BLUEMIX'
  | 'IBM'
  | 'OPENSHIFT'
  | 'CloudfoundryPartner';

export enum AddressChangeOptions {
  CAN_CHANGE_TO_ANY = 'can_change_to_any',
  CANNOT_CHANGE = 'cannot_change',
  REMAIN_LTD = 'remain_ltd',
  REMAIN_INC = 'remain_inc'
}

export type AddressChangeOption =
  | AddressChangeOptions.CAN_CHANGE_TO_ANY
  | AddressChangeOptions.CANNOT_CHANGE
  | AddressChangeOptions.REMAIN_LTD
  | AddressChangeOptions.REMAIN_INC;

type ContractPaymentInfoMethod = 'credit-card' | 'wired-transfer';

export interface Account {
  id: number;
  name: string;
  is_ltd: boolean;
  has_zone_mapping: boolean;
  has_paid: boolean;
  should_create_shopper: boolean;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  state: string;
  state_name: string;
  country: string;
  country_name: string;
  has_memcached: boolean;
  has_redis: boolean;
  company_tax_id: string | null;
  vat_id: string | null;
  owner: IOwner;
  has_mfa_enforcement: boolean;
  creator_name: string;
  creator_email: string;
  posted_time: string;
  last_updated_time: string;
  time_zone: string;
  paas?: Paas;
  product_type: string;
  payment_info: number;
  rcp_enabled: boolean;
  trial_rcp_subscription_id?: number;
  trial_rcp_subscription_expiration_date?: string;
  can_change_trial_payment?: boolean;
  is_rvrc: boolean;
  can_manage_cloud_account: boolean;
  is_api: boolean;
  api_access_key: string;
  is_sso_gcp: boolean;
  is_support_simplified_plans: boolean;
  is_support_shard_type: boolean;
  is_support_psc: boolean;
  is_support_tgw: boolean;
  is_support_tgw_aa: boolean;
  is_support_marketplace_fixed: boolean;
  is_sm_billing_payment_provider: boolean;
  errors: unknown[];
  cloud_contract?: null | number;
  cloud_contract_start_time?: string;
  cloud_contract_end_time?: string;
  cloud_contract_payment_info?: null | number;
  cloud_contract_payment_method?: ContractPaymentInfoMethod;
  marketplace?: null | Marketplace;
  is_poc?: boolean;
  poc_account_expiration_date?: string;
  saml_config_id: null | string;
  address_change_option: AddressChangeOption;
  address_change_blocked_reason?: AddressChangeBlockedReason;
  has_overdue_open_invoices?: boolean;
  can_generate_cost_report: boolean;
}

export interface AccountResponse {
  accounts: Account[];
}

export interface CloudApiAccessResponse {
  accessKey: string;
  errors: string[];
}

export enum AddressChangeBlockedReasons {
  OPEN_INVOICES = 'open_invoices',
  OVERDUE_OPEN_INVOICES = 'overdue_open_invoices',
  REMAIN_LTD = 'remain_ltd'
}
export type AddressChangeBlockedReason =
  | AddressChangeBlockedReasons.OPEN_INVOICES
  | AddressChangeBlockedReasons.OVERDUE_OPEN_INVOICES
  | AddressChangeBlockedReasons.REMAIN_LTD;
