import { AxiosError } from 'axios';
import { UseQueryOptions } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import usersKeys from './users.keys';
import { usersApi } from '../../services/api/resources/users/users.resource';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import { UserTypeEnum } from '../../types/enums';
import { filterPermissions, updatePermissions } from '../../store/auth/auth.action';
import { filterSamlPermissions } from '../../components/PermissionsProtectors/utils/filterSsoPermisions';
import { MANAGE_PASSWORDS_PERMISSION } from '../../components/PermissionsProtectors/utils/permissions.constants';
import useSmQuery from '../useSmQuery';
import {
  accountStateSelector,
  isGcpAccountSelector,
  isHerokuAccountSelector,
  isVercelAccountSelector
} from '../../store/account/account.selectors';
import { ApiAuthResponse } from '../../store/auth/auth.types';
import { ExtendedAxiosError } from '../../types/interfaces';

const useUsersQuery = (queryOptions: UseQueryOptions<User[]> = {}) => {
  const getUsers = async () => {
    const { data } = await usersApi.getAll();

    return data.users;
  };

  return useSmQuery({
    permissionKey: 'user_get_users',
    queryKey: usersKeys.getAll,
    queryFn: getUsers,
    onError: (e: AxiosError) => {
      extractErrorAndShowToast(e);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

const useUsersRolesQuery = (queryOptions: UseQueryOptions<UserRole[]> = {}) => {
  const getUsersRoles = async () => {
    const { data } = await usersApi.getAllRoles();

    return data.roles;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: usersKeys.getAllRoles,
    queryFn: getUsersRoles,
    onError: (e: AxiosError) => {
      extractErrorAndShowToast(e);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

const useUserQuery = (userId: number, queryOptions: UseQueryOptions<User> = {}) => {
  const dispatch = useDispatch();
  const [userState, setUserState] = useState(null);
  const isHerokuAccount = useSelector(isHerokuAccountSelector);
  const isVerceAccount = useSelector(isVercelAccountSelector);
  const isGcpAccount = useSelector(isGcpAccountSelector);
  const {
    accountState: { status: accountStatus }
  } = useSelector(accountStateSelector);

  useEffect(() => {
    if (accountStatus === 'resolved' && userState !== null) {
      if (
        !isHerokuAccount &&
        !isVerceAccount &&
        !isGcpAccount &&
        userState?.user_type === UserTypeEnum.local
      ) {
        dispatch(updatePermissions([MANAGE_PASSWORDS_PERMISSION]));
      }

      if (userState?.user_type === UserTypeEnum.saml) {
        dispatch(filterPermissions(filterSamlPermissions));
      }
    }
  }, [accountStatus, dispatch, isGcpAccount, isHerokuAccount, userState]);

  const getUser = async () => {
    const { data } = await usersApi.getOne(userId);

    return data.user;
  };

  return useSmQuery({
    permissionKey: 'user_get_user',
    queryKey: usersKeys.getUser(userId),
    queryFn: getUser,
    onError: (e: AxiosError) => {
      extractErrorAndShowToast(e);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions,
    onSuccess: (user) => {
      // TODO: this is a temp fix, once we move permission payload to a new endpoint, this will become obsolete
      setUserState(user);
      queryOptions.onSuccess?.(user);
    }
  });
};

const useCurrentUserQuery = (queryOptions: UseQueryOptions<ApiAuthResponse> = {}) => {
  const getCurrentUser = async () => {
    const { data } = await usersApi.getCurrentUser();

    return data;
  };

  return useSmQuery({
    permissionKey: null,
    queryKey: usersKeys.getCurrentUser,
    queryFn: getCurrentUser,
    onError: (e: AxiosError) => {
      extractErrorAndShowToast(e);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions
  });
};

const useRegistrationCallbackQuery = (
  search: string,
  queryOptions: UseQueryOptions<{}, ExtendedAxiosError> = {}
) => {
  const registrationCallback = async () => {
    const response = await usersApi.registrationCallback(search);

    return response;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: usersKeys.registrationCallback(search),
    queryFn: registrationCallback,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions
  });
};

const useResetPasswordCallbackQuery = (
  search: string,
  queryOptions: UseQueryOptions<{}, ExtendedAxiosError> = {}
) => {
  const resetPasswordCallback = async () => {
    const response = await usersApi.resetPasswordCallback(search);

    return response;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: usersKeys.resetPasswordCallback(search),
    queryFn: resetPasswordCallback,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions
  });
};

export const usersQueries = {
  useUsers: useUsersQuery,
  useUsersRoles: useUsersRolesQuery,
  useUser: useUserQuery,
  useCurrentUser: useCurrentUserQuery,
  useRegistrationCallback: useRegistrationCallbackQuery,
  useResetPasswordCallback: useResetPasswordCallbackQuery
};
