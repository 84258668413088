import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { values } from 'lodash';
import { getPlans } from 'store/subscriptions/plans/plans.actions';
import { getSubscriptions } from 'store/subscriptions/subscriptions.actions';
import { isLoggedInSelector } from 'store/auth/auth.selectors';
import { plansSelector } from '../../../store/subscriptions/plans/plans.selectors';
import { subscriptionStateSelector } from '../../../store/subscriptions/subscriptions.selectors';
import oldCalculateSubscriptionPrice from '../../../components/oldPricing/utils/oldCalculateSubscriptionPrice';
import getSubscriptionPrice from '../../../screens/SubscriptionsAndDatabases/Subscriptions/SubscriptionDetails/components/Overview/components/PricingCard/helpers/getSubscriptionPrice';
import { isOldPricingSelector } from '../../../components/oldPricing/selectors/oldPricing.selectors';
import { getPlanType } from '../../../utils';
import { parametersQueries } from '../../../queryClient/parameters/parameters.queries';
import { pendoQueries } from '../../../queryClient/pendo/pendo.queries';
import { isEnforcedMfaRoleSelector } from '../../../store/mfa/mfa.selectors';

const getMonthlyPrice = (isOldPricing: boolean, subscription: Subscription, plan: Plan): number => {
  const isSubReserved = subscription.rcp?.rcp_type === 'reserved';
  const { isEssential } = getPlanType(plan);

  return isOldPricing || (!!subscription.id && isSubReserved)
    ? Number(
        oldCalculateSubscriptionPrice(
          subscription.rcp?.nodes,
          subscription.rcp?.monthly_databases_price,
          subscription.rcp?.monthly_storage_price
        ).monthlyPrice
      )
    : getSubscriptionPrice({
        isEssential,
        plan,
        subscription,
        isCloudAccountExternal: subscription?.rcp?.cloud_account?.ownership === 'external'
      });
};

const usePendo = () => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { pendoInfo, isFetched: isPendoInfoFetched } = pendoQueries.usePendo({
    enabled: isLoggedIn && systemParams.pendo_enabled
  });
  const { data: plans, status: plansStatus } = useSelector(plansSelector);
  const { data: allSubs, status: subscriptionsStatus } = useSelector(subscriptionStateSelector);
  const isEnforcedMfaRole = useSelector(isEnforcedMfaRoleSelector);
  const isOldPricing = useSelector(isOldPricingSelector);
  const isPendoInitializedRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEnforcedMfaRole && isLoggedIn) {
      dispatch(getPlans());
      dispatch(getSubscriptions());
    }
  }, [dispatch, isLoggedIn, isEnforcedMfaRole]);

  useEffect(() => {
    if (
      !isLoggedIn ||
      !systemParams.pendo_enabled ||
      isPendoInitializedRef.current ||
      !isPendoInfoFetched ||
      !pendoInfo?.account_info ||
      !pendoInfo?.user_info ||
      plansStatus !== 'resolved' ||
      subscriptionsStatus !== 'resolved'
    ) {
      return;
    }

    let monthlyValue = 0;

    values(allSubs).forEach((sub) => {
      monthlyValue += getMonthlyPrice(isOldPricing, sub, plans[sub.plan]);
    });

    // @ts-ignore
    window.pendo?.initialize({
      visitor: {
        ...pendoInfo.user_info
      },
      account: {
        ...pendoInfo.account_info,
        monthly_value: monthlyValue // Recommended if using Pendo Feedback
      }
    });
    isPendoInitializedRef.current = true;
  }, [
    pendoInfo?.user_info,
    pendoInfo?.account_info,
    isPendoInfoFetched,
    plans,
    plansStatus,
    subscriptionsStatus,
    allSubs,
    isOldPricing,
    systemParams.pendo_enabled,
    isLoggedIn
  ]);

  return null;
};

export default usePendo;
