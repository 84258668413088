import { FC } from 'react';
import { isEmpty } from 'lodash';
import { CloudProviders } from 'components/CloudProvider/CloudProvider.types';
import { getAllAvailableProviders } from 'screens/CreateSubscription/SubscriptionTypes/Essential/components/CloudVendorAndReplication/utils/getAllAvailableProviders';
import useRegions from 'hooks/useRegions';
import { sortRegions } from 'screens/CreateSubscription/SubscriptionTypes/Essential/utils/sortRegions';
import CloudAndRegionSelection from 'screens/CreateSubscription/components/CloudAndRegionSelection/CloudAndRegionSelection';
import { getFirstRegion } from '../../utils/loginRelated';
import { FreeDbCloudVendorProps } from '../../FreeDb.types';

const FreeDbCloudVendor: FC<FreeDbCloudVendorProps> = ({
  freePlans,
  selectedVendor,
  setSelectedVendor,
  selectedRegion,
  setSelectedRegion,
  vendorRegionPlanMapper,
  isOptedToNewRedis,
  isSupportingNewRedisOptIn,
  redisOptInVersion,
  newRedisOptInRegionIds,
  onNewRedisOptInClick
}) => {
  const { getRegionByRegionName } = useRegions();

  const regions = Object.keys(vendorRegionPlanMapper[selectedVendor] || {});
  const providers = getAllAvailableProviders(freePlans, isOptedToNewRedis);
  const allRegions = sortRegions(regions.map((regionName) => getRegionByRegionName(regionName)));

  if (isEmpty(vendorRegionPlanMapper)) {
    return null;
  }

  return (
    <CloudAndRegionSelection
      allRegions={allRegions}
      cloudProviders={providers}
      onCloudSelect={(newVendor: CloudProviders) => {
        if (newVendor !== selectedVendor) {
          setSelectedVendor(newVendor);
          setSelectedRegion(
            getFirstRegion(vendorRegionPlanMapper[newVendor], getRegionByRegionName)
          );
        }
      }}
      onRegionSelect={(newRegion) => {
        if (newRegion?.name) {
          setSelectedRegion(newRegion.name);
        } else {
          setSelectedRegion(
            getFirstRegion(vendorRegionPlanMapper[selectedVendor], getRegionByRegionName)
          );
        }
      }}
      selectedProvider={selectedVendor}
      selectedRegion={getRegionByRegionName(selectedRegion)}
      isOptedToNewRedis={isOptedToNewRedis}
      isSupportingNewRedisOptIn={isSupportingNewRedisOptIn}
      redisOptInVersion={redisOptInVersion}
      newRedisOptInRegionIds={newRedisOptInRegionIds}
      onNewRedisOptInClick={onNewRedisOptInClick}
    />
  );
};

export default FreeDbCloudVendor;
